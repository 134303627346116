@media screen and (max-width: 991px) {
  .alert {
    margin-left: 0 !important;
  margin-top: 17%!important;

  }
  .listGroup{
    display: flex;
  }
}

.alert {

  margin-top: 7%;
  margin-left: 20%;
  .linear-heading {
    
    font-weight: 800;
   

    font-size: 22px;
    background: #d81bd6;
    background: linear-gradient(
      to top left,
      #d81bd6 42%,
      #07258f 77%,
      #07258f 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    
  }
  .linear-text{
    color: #07258f ;
    font-weight: 700;

  }
  .icon {
  
    margin-left: 10px;
  }
}
