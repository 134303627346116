@import "./variables";

body {
  font-family: $ffPrimary;
  color: $textPrimary;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  .container {
    max-width: 1300px;
  }
  .MuiToolbar-root {
    min-height: 60px !important;
  }
  #shadow-host-companion {
    padding: 0;
  }
}


.btn {
  border-radius: 0.625rem;
}
