@import "./variables";

.app-header {
  nav {
    padding: 3rem 0;
    .navbar-brand {
      .brand-logo {
        transition: 0.15s all linear;
        max-width: 18rem;
      }
    }
    .navbar-nav {
      a {
        color: white;
        margin-left: 1.5rem;
        &.btn {
          background: white;
          color: #000;
          width: 8.4375rem;
          height: 2.5rem;
          margin-left: 1.5rem;
          font-weight: 700;
        }
      }
    }
  }
  &.sticky-top {
    nav {
      padding: 1rem 0;
      //   background: white;
      background: rgba(16, 16, 18, 0.8) !important;

      .navbar-brand {
        margin-left: -1rem;
        .brand-logo {
          max-width: 12rem;
          height: 0;
        }
      }
    }
  }
}
