@import "./variables";
@media screen and (max-width: 991px) {
  .footerlogo{
  // align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}}
.app-footer {
  
  p {
    color: #626262;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
  }
  hr {
    border-color: #cfd1d4;
    margin: 2.5rem 0;
  }
  .social-icons {
    list-style: none;
    padding: 0.5rem 0;
    // justify-content: center;
    a {
      text-decoration: none;
      background: black;
      border-radius: 100px;
      display: flex;
      width: 30px;
      height: 30px;
      padding: 7px;
      justify-content: center;
      align-items: center;
      background: #626262;
    }
  }
  .list-group {
   
    label {
      color: #2c3131;
      font-size: 18px;
      font-weight: 600;
      line-height: 145%;
      margin-bottom: 1rem;
    }
    a {
      margin-bottom: 1rem;
      text-decoration: none;
      color: #70747d;
      font-size: 16px;
      font-weight: 400;
      line-height: 150%;
    }
  }
  .text-copyright {
    text-align: center;
    color: #a0a3a9;
    font-size: 16px;
    font-weight: 400;
   
    margin: 3rem 0;
  }
}

