// COLORS
$themePrimaryColor: #0156f7;
$themeSecondaryColor: #f903ff;

$textTitleColor: #2c3131;
$textPrimary: #333333;
$textSecondary: #9199a3;

// FONT FAMILY
$ffPrimary: "Inter", sans-serif;
$ffSecondary: "Poppins", sans-serif;
